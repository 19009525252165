<template>
    <modal ref="modalEditCalendar" titulo="Editar calendario" adicional="Guardar" @adicional="add">
        <div class="row m-3 f-15">
            <div class="col-12">
                <p class="input-label-top">Nombre</p>
                <el-input v-model="generalCalendarr.nombre" class="w-100" size="small" />
            </div>
        </div>
    </modal>
</template>

<script>
import { GeneralCalendarFactory } from '~/domain/entities/configurar/obras/calendario_general/factories/GeneralCalendarFactory'
export default {
    data(){
        return {
            generalCalendarr: GeneralCalendarFactory.instantiateEmpty() 
        }
    },
    methods: {
        toggle(generalCalendar){
            if(generalCalendar){
                this.generalCalendarr = {...generalCalendar}
            }
            this.$refs.modalEditCalendar.toggle()
        },
        add(){
            this.$emit('editGeneralCalendar', this.generalCalendarr)
            this.generalCalendarr = GeneralCalendarFactory.instantiateEmpty() 
            this.toggle()
        }
    }
}
</script>